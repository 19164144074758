export default {
    VIEWER: {
        value: 'viewer',
        title: 'Viewer',
        defaultPage: '/fly',
        available: true,
        onlyAdminCreate: false,
    },
    TEAM: {
        value: 'team',
        title: 'Team',
        defaultPage: '/fly',
        available: true,
        onlyAdminCreate: false,
    },
    ISTAR_DIVISION: {
        value: 'istar_division',
        title: 'ISTAR division',
        defaultPage: '/fly',
        available: true,
        onlyAdminCreate: true,
    },
    ISTAR_ZONE: {
        value: 'istar_zone',
        title: 'ISTAR zone',
        defaultPage: '/zone-admin',
        available: true,
        onlyAdminCreate: true,
    },
    OTG_COORDINATOR: {
        value: 'otg_coordinator',
        title: 'OTG Coordinator',
        defaultPage: '/zone-admin',
        available: true,
        onlyAdminCreate: true,
    },
    ISTAR_BRIGADE: {
        value: 'istar_brigade',
        title: 'ISTAR brigade',
        defaultPage: '/fly',
        available: true,
        onlyAdminCreate: true,
    },
    ADMIN: {
        value: 'admin',
        title: 'Admin',
        defaultPage: '/fly',
        available: true,
        onlyAdminCreate: true,
    },
};